@import url('https://fonts.googleapis.com/css2?family=Krona+One&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  position: relative;
  overflow: hidden;
  line-height: 1.15;
}

.site-container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100vh;
  max-height: -webkit-fill-available;
}

.page-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
}

main {
  flex-direction: column;
  display: flex;
  flex: 1 1 0%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

a {
  text-decoration: none;
  transition: 0.5s;
  color: white;
}

a:hover {
  color: black;
}

.fancy-link {
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem;

  background-size: 200% 100%;
  background-position: 100% 0;
  transition: background-position 0.5s;
}

.fancy-link:hover {
  color: #fff;
  box-shadow: 0 0 15px rgba(0 0 0 / 0.25);
  background-position: 0 0;
}

.fancy-bg-blue {
  background-image: radial-gradient(
    circle farthest-corner at 51.2% 51%,
    rgba(11, 27, 103, 1) 0%,
    rgba(16, 66, 157, 1) 0%,
    rgba(11, 27, 103, 1) 17.3%,
    rgba(11, 27, 103, 1) 58.8%,
    rgba(11, 27, 103, 1) 71.4%,
    rgba(16, 66, 157, 1) 100.2%,
    rgba(187, 187, 187, 1) 100.2%
  );
}

.fancy-bg-red {
  background-image: radial-gradient(
    circle farthest-corner at 51.2% 51%,
    rgba(247, 87, 0, 1) 0%,
    rgba(249, 0, 0, 1) 90.1%
  );
}

.bg-black {
  background: #000;
}

.block {
  display: block;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-bold {
  font-weight: 600;
}

.font-medium {
  font-weight: 400;
}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.duration-100 {
  transition-duration: 100ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.spin {
  animation: spin 8s linear infinite;
}

.pulse {
  animation: pulse 2s cubic-bezier(0.1, 0, 0.6, 1) infinite;
}

.animate-fade-in {
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
  -ms-animation: fadein 1.5s; /* Internet Explorer */
  -o-animation: fadein 1.5s; /* Opera < 12.1 */
  animation: fadein 1.5s;
}

.animate-fade-in-rise {
  -webkit-animation: fadein_rise 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein_rise 1.5s; /* Firefox < 16 */
  -ms-animation: fadein_rise 1.5s; /* Internet Explorer */
  -o-animation: fadein_rise 1.5s; /* Opera < 12.1 */
  animation: fadein_rise 1.5s;
}

.animate-slide-right {
  -webkit-animation: slideRight 0.5s;
  -moz-animation: slideRight 0.5s;
  -ms-animation: slideRight 0.5s;
  -o-animation: slideRight 0.5s;
  animation: slideRight 0.5s;
}

.animate-slide-left {
  -webkit-animation: slideLeft 0.5s;
  -moz-animation: slideLeft 0.5s;
  -ms-animation: slideLeft 0.5s;
  -o-animation: slideLeft 0.5s;
  animation: slideLeft 0.5s;
}

.animate-rotate-90-25 {
  -webkit-animation: rotate_90_25 1.5s;
  -moz-animation: rotate_90_25 1.5s;
  -ms-animation: rotate_90_25 1.5s;
  -o-animation: rotate_90_25 1.5s;
  animation: rotate_90_25 1.5s;
}

/* ANIMATIONS */

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* BOUNCE */
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-1rem);
    animation-timing-function: ease-in-out;
  }

  50% {
    transform: translateY(0);
    animation-timing-function: ease-in-out;
  }
}

/* Firefox < 16 */
@-moz-keyframes bounce {
  0%,
  100% {
    transform: translateY(-1rem);
    animation-timing-function: ease-in-out;
  }

  50% {
    transform: translateY(0);
    animation-timing-function: ease-in-out;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes bounce {
  0%,
  100% {
    transform: translateY(-1rem);
    animation-timing-function: ease-in-out;
  }

  50% {
    transform: translateY(0);
    animation-timing-function: ease-in-out;
  }
}

/* Internet Explorer */
@-ms-keyframes bounce {
  0%,
  100% {
    transform: translateY(-1rem);
    animation-timing-function: ease-in-out;
  }

  50% {
    transform: translateY(0);
    animation-timing-function: ease-in-out;
  }
}

/* Opera < 12.1 */
@-o-keyframes bounce {
  0%,
  100% {
    transform: translateY(-1rem);
    animation-timing-function: ease-in-out;
  }

  50% {
    transform: translateY(0);
    animation-timing-function: ease-in-out;
  }
}
/* END BOUNCE */

/* FADE IN */
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* END FADE IN */

/* FADE IN RISE */
@keyframes fadein_rise {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein_rise {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein_rise {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/* Internet Explorer */
@-ms-keyframes fadein_rise {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein_rise {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
/* END FADE IN RISE */

/* SLIDERIGHT */

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Firefox < 16 */
@-moz-keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Internet Explorer */
@-ms-keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Opera < 12.1 */
@-o-keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* END SLIDERIGHT */

/* SLIDELEFT */

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Firefox < 16 */
@-moz-keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Internet Explorer */
@-ms-keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Opera < 12.1 */
@-o-keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

/* END SLIDELEFT */

/* SPIN */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Firefox < 16 */
@-moz-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Internet Explorer */
@-ms-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Opera < 12.1 */
@-o-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* END SPIN */

/* ROTATE 90-25 */
@keyframes rotate_90_25 {
  0% {
    opacity: 0.25;
    transform: rotate(90deg);
  }
  100% {
    opacity: 0.9;
    transform: rotate(25deg);
  }
}
/* Firefox < 16 */
@-moz-keyframes rotate_90_25 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(25deg);
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes rotate_90_25 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(25deg);
  }
}
/* Internet Explorer */
@-ms-keyframes rotate_90_25 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(25deg);
  }
}
/* Opera < 12.1 */
@-o-keyframes rotate_90_25 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(25deg);
  }
}

/* END ROTATE 90-25 */
