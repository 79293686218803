.tools__container {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  width: 100%;
  padding: 2rem;
  text-align: center;
  color: #fff;

  border-radius: 10px;

  /* background: #4f4f4f;
  box-shadow: 15px 15px 40px #434343, -15px -15px 40px #5b5b5b; */

  background: linear-gradient(145deg, #5b5b5b, #4d4d4d);
  box-shadow: 10px 10px 30px #2c5466, -10px -10px 30px #2c5466;
}

.tools__line-item--container > svg > path {
  stroke: #fff;
}

.tools__line-item--container {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: flex-end;
  place-items: center;
}

.tools__card-title {
  border-bottom: 1px solid white;
}

@media only screen and (max-width: 676px) {
  .tools__container {
    padding: 1rem;
  }
  .tools__line-item--container {
    flex-direction: column;
    row-gap: 1rem;
  }
  .tools__card-title,
  .tools__card-copy {
    font-size: 1rem;
  }
}
