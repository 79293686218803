.projects-main {
  /* background-image: url('https://cdn.pixabay.com/photo/2021/04/10/10/32/graphic-6166581_1280.jpg');

  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover; */

  background-color: #21d4fd;
  /* background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%); */

  background-image: linear-gradient(
    /* 145deg, */ 25deg,
    hsl(191deg 98% 56%) 0%,
    hsl(192deg 100% 50%) 1%,
    hsl(193deg 100% 50%) 1%,
    hsl(195deg 100% 50%) 2%,
    hsl(197deg 100% 50%) 4%,
    hsl(204deg 100% 60%) 6%,
    hsl(211deg 100% 65%) 8%,
    hsl(220deg 100% 70%) 11%,
    hsl(233deg 100% 75%) 16%,
    hsl(249deg 92% 74%) 24%,
    hsl(265deg 78% 69%) 38%,
    hsl(280deg 66% 64%) 62%,
    hsl(306deg 61% 62%) 75%,
    hsl(324deg 81% 67%) 82%,
    hsl(337deg 97% 72%) 87%,
    hsl(348deg 100% 75%) 90%,
    hsl(0deg 100% 78%) 93%,
    hsl(11deg 100% 77%) 95%,
    hsl(19deg 98% 76%) 97%,
    hsl(27deg 88% 76%) 98%,
    hsl(35deg 76% 77%) 99%,
    hsl(43deg 63% 79%) 99%,
    hsl(56deg 48% 82%) 100%
  );

  /* background-image: url('https://cdn.pixabay.com/photo/2021/04/10/10/32/graphic-6166581_1280.jpg'); */
  padding: 1rem;
  padding-bottom: 3rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-items: center;
  row-gap: 4rem;
}

.projects-header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  place-items: center;
  text-align: center;
  row-gap: 0.5rem;
  color: #fff;
  font-family: ubuntu;

  border-radius: 10px;
  background: rgba(93, 0, 137, 0.25);
  box-shadow: 0 8px 32px rgba(255, 255, 255, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.projects-header h1 {
  font-size: 3rem;
  font-weight: 600;
}

.projects-header h4 {
  font-weight: 500;
  font-size: larger;
}

.project-selector__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.5rem;
  width: 100%;

  /* -webkit-animation: fadein 1s;
  -moz-animation: fadein 1s;
  -ms-animation: fadein 1s;
  -o-animation: fadein 1s;
  animation: fadein 1s; */
}

.project-selector__container h1 {
  color: #fff;
  font-weight: 500;
  border-bottom: 2px solid #fff;
}

.projects__data--container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2rem;
}

.projects__carousel--container {
  width: 50%;
  padding: 1rem;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.projects__info-card {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 50%;

  color: #fff;
  padding: 1rem;
  border-radius: 1rem;

  background: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 15px rgba(0 0 0 / 0.25);
  box-shadow: 0 0 15px rgba(0 0 0 / 0.5);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.projects__info-card > div {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}

.projects__info-card h1 {
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.25),
    inset -2px -2px 10px rgba(0, 0, 0, 0.3);
}

.projects-disclaimer {
  color: yellow;
}

@media only screen and (min-width: 1050px) {
  .project-selector__button {
    min-width: 20rem;
  }
}

@media only screen and (max-width: 1050px) {
  .projects-main {
    row-gap: 2rem;
  }
  .projects-header {
    padding: 1rem;
  }
  .project-selector__container {
    flex-direction: column;
    place-items: center;
    max-width: 90vw;
  }
  .project-selector__container h1 {
    border-bottom: none;
  }
  .project-selector__button {
    width: 90%;
  }
  .projects__data--container {
    flex-direction: column;
    place-items: center;
    max-width: 90vw;
  }
  .projects__carousel--container {
    /* order: 2; */

    width: 100%;
    padding: 0.5rem;
  }
  .projects__info-card {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .projects__carousel--container {
    padding: 0.25rem;
    border-radius: 5px;
  }
  .projects-header h1,
  .project-selector__container h1,
  .projects__info-card h1 {
    font-size: 1.5rem;
  }
  .projects-header h4 {
    font-size: 1rem;
  }

  /* .projects__carousel--arrow-button, */
  .projects__carousel--indicator-button,
  .projects__carousel--arrow-button-icon {
    display: none !important;
  }
}
