.skills__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  place-content: center;
  gap: 1rem;
}

.skills-card {
  width: 23%;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  border-radius: 1rem;
  /* background: linear-gradient(145deg, #ffffff, #ebebeb);
  box-shadow: 5px 5px 10px #969696, -5px -5px 10px #ffffff; */

  /* background: linear-gradient(145deg, #4e81a0, #5d99be);
  box-shadow: 5px 5px 10px #2b4657, -5px -5px 10px #83d8ff; */
  /* color: #fff; */

  background: linear-gradient(145deg, #d6d6d6, #ffffff);
  box-shadow: 5px 5px 10px #757575, -5px -5px 10px #ffffff;
}

.skills-card svg {
  max-width: 10rem;
}

.skills-ellipses-icon {
  background: linear-gradient(145deg, #e59999, #7d60ff, #46d6e6);
  border-radius: 50%;
}

.skills-card__body-list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  text-align: center;
  font-weight: 500;
}

@media only screen and (max-width: 776px) {
  .skills-card {
    width: 45%;
    padding: 0.5rem;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .skills-card {
    width: 95%;
  }

  .skills-card__heading {
    font-size: 1rem;
  }
}
