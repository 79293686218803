.about-main {
  background: linear-gradient(
    160deg,
    rgb(26, 81, 148) 0%,
    rgb(119, 176, 195) 50%,
    rgb(109, 209, 255) 100%
  );
  padding: 1rem;
  padding-bottom: 3rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-items: center;
  row-gap: 2rem;
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-items: center;
  row-gap: 3rem;
  max-width: 90%;
}

.about-header {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  place-items: center;
  text-align: center;
  row-gap: 0.5rem;
  color: #fff;
}

.about-header h1 {
  font-size: 3rem;
  font-weight: 400;
}

.about-header h4 {
  font-weight: 500;
  font-size: larger;
}

.about__secondary-header h1 {
  color: #b81474;
}
.about__secondary-header h4 {
  padding: 0.5rem;
  border-radius: 5px;
  background: #b81474;
  box-shadow: inset 26px 26px 51px #9c1163, inset -26px -26px 51px #d41785;
}

.about__bio-content-container {
  display: flex;
  justify-content: space-around;
  place-items: center;
  column-gap: 2rem;
  row-gap: 1rem;
  max-width: 1200px;
  width: 100%;
}

.about__bio-content-text {
  display: grid;
  row-gap: 2rem;
  color: #fff;
  padding: 1rem;
  border-radius: 1rem;

  background: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 15px rgba(0 0 0 / 0.25);
  box-shadow: 0 0 15px rgba(0 0 0 / 0.5);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.about__bio-content-text--header {
  display: grid;
  row-gap: 0.5rem;
  border-bottom: 1px solid rgb(119, 176, 195);
}

.about__bio-content-text--header-name {
  font-size: 2.5rem;
}

.about__bio-content-text--header-title {
  font-size: 1.25rem;
}

.about__bio-content-headshot {
  width: 100%;
  max-width: 20rem;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
}

.g-a-link {
  --ga-pink: rgb(254, 169, 169);
  color: var(--ga-pink);
  transition: none;
}

.g-a-link:hover {
  color: var(--ga-pink);
  text-decoration: underline;
}

.about__bio-content--buttons-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media only screen and (max-width: 800px) {
  .about__bio-content-text {
    text-align: center;
    order: 2;
  }

  .about__bio-content-container {
    display: grid;
  }
}

@media only screen and (max-width: 676px) {
  .about-section {
    row-gap: 1rem;
  }

  .about__bio-content-text--header {
    /* border: 1px solid white; */
    border-radius: 0.5rem;
    padding: 0.5rem;
    border: none;
    box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.25),
      inset -2px -2px 10px rgba(0, 0, 0, 0.3);
  }

  .about__bio-content-text--header-name {
    font-size: 1.5rem;
  }

  .about__bio-content-text--header-title {
    font-size: 1rem;
  }

  .about__bio-content-headshot {
    order: 1;
  }
  .about-header {
    padding: 1rem 0;
  }

  .about-header h1 {
    font-size: 2rem;
  }
  .about__bio-content-text {
    padding: 5%;
  }
  .about__bio-content--buttons-container {
    place-content: center;
  }
}

@media only screen and (max-width: 400px) {
  .about__bio-content--buttons-container {
    flex-direction: column;
  }
  .about__secondary-header h1 {
    font-size: 1.5rem;
  }
}
