.nav-container {
  background: rgb(58, 157, 228);
  height: 4rem;
  padding: 0 1rem;
  display: flex;
  place-items: center;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  transition: 0.5s;
}

.nav-to-top {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  z-index: 2;
  opacity: 0.75;
  border: 1px solid black;
  height: 2.25rem;
  width: 2.25rem;
  display: grid;
  place-items: center;
  padding: 0.5rem;
  transition: 0.1s;
  border-radius: 50%;
  cursor: pointer;
  background: rgb(164, 213, 255);
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.5);
}

.nav-to-top:active {
  opacity: 1;
}

.menu-toggle-button {
  cursor: pointer;
  z-index: 100;
  display: none;
}

.side-nav {
  display: none;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.25s;
  z-index: 50;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
}

#closed {
  transform: translateX(-100%);
}

#open {
  transform: translateX(0);
}

.sidebar-links {
  background: rgba(31, 87, 135, 0.95);
  transition: 0.5;
  display: flex;
  flex-direction: column;
  place-content: center;
  justify-content: space-around;
  text-align: center;
  box-shadow: 10px -5px 10px rgba(0, 0, 0, 0.5);
  min-height: 100%;
  min-width: 100%;
}

.sidebar-links a {
  display: flex;
  place-items: center;
  justify-content: center;
  flex: 1;
  padding: 1rem 0;
}

.sidebar-links a:hover {
  color: rgb(126, 126, 241);
  background: rgba(213, 227, 238, 0.863);
}

.nav-container > * {
  font-size: 1.25rem;
  text-align: center;
}

.signature {
  text-transform: none;
  padding: 0;
  font-family: Raleway;
  color: #e2f0fb;
}

.nav-left,
.nav-right {
  list-style: none;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.side-nav .active {
  color: rgb(176, 176, 245);
}
.active {
  color: rgba(0, 0, 0, 0.5);
}

.nav-right-icon {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: grid;
  place-items: center;
}

.nav-right-icon:hover {
  background: black;
}

@media only screen and (min-width: 1000px) {
  .signature {
    transition: 0.5s;
  }
  .signature:hover {
    color: #003d66;
  }
}

@media only screen and (max-width: 1000px) {
  .nav-right > .nav-right-icon {
    display: none;
  }
  .signature {
    margin-right: 0.5rem;
  }
}

@media only screen and (max-width: 620px) {
  .menu-toggle-button,
  .side-nav {
    display: unset;
  }
  .nav-left {
    display: none;
  }
}

@media only screen and (max-width: 300px) {
  .signature {
    font-size: 1rem;
  }
  .nav-container {
    padding: 0;
  }
}
