.experience-main {
  /* background-color: rgb(62, 107, 189); */
  /* background-color: #6687c4; */
  /* background: rgb(33, 43, 58); */

  /* background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  ); */

  background-color: #ff3cac;
  background-image: linear-gradient(
    225deg,
    #2b86c5 0%,
    #784ba0 50%,
    #ff3cac 100%
  );
}

.experience-header {
  background-color: #a100ff;
  color: white;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.5rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.experience-header > * {
  margin: 0;
}

.experience-item img {
  border-radius: 0.5rem;
  width: 100%;
}

.experience-description h2 {
  font-size: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #aaa;
}

.experience-description > * {
  margin: 0;
}

.experience-description h1 {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 800;
}

.experience-description h3 {
  font-family: 'Courier New', Courier, monospace;
  font-weight: 400;
  font-size: 1rem;
}

.experience-item img {
  max-width: 300px;
  max-height: 300px;
  background: rgba(255, 255, 255, 0.85);
  -webkit-box-shadow: 0 0 15px rgba(0 0 0 / 0.25);
  box-shadow: 0 0 15px rgba(0 0 0 / 0.25);
}

.experience-item .logo-padding {
  padding: 0.5rem;
}

.timeline-date {
  color: #000;
}

.bookmarkletSvgIcon {
  transform: scale(1.25) translateY(0.1rem);
}

a.dissertation {
  color: #0d5330;
  text-decoration: underline;
}

a.dissertation:hover {
  color: #f47525;
}

.ga-link-sei {
  color: rgb(185, 71, 71);
  text-decoration: underline;
}

.ga-link-sei:hover {
  color: rgba(255, 0, 0, 0.568);
}

.timeline__um-icon {
  transform: translateY(0.85rem) scale(0.8);
}

.timeline__ga-icon {
  transform: translateY(0.3rem);
}

@media only screen and (min-width: 1170px) {
  .experience-header {
    place-items: center;
  }
  .experience-item img {
    width: 50%;
    min-height: 150px;
    margin: 0.5rem;
    float: right;
  }
  .experience-description h1 {
    font-size: 1.15rem;
  }
  .responsiveImgSm {
    display: none;
  }
  .bookmarkletSvgIcon {
    transform: scale(2) translateY(0.1rem);
  }
  .timeline-date {
    color: #fff;
  }
}

@media only screen and (max-width: 1170px) {
  .responsiveImgLg {
    display: none;
  }
  .experience-item img {
    margin-bottom: 1rem;
  }
  .timeline__um-icon {
    transform: translateY(0.5rem) scale(0.8);
  }
  .timeline__ga-icon {
    transform: translateY(0.175rem);
  }
}

@media only screen and (min-width: 767px) and (max-width: 1170px) {
  .experience-item img {
    width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .experience-header {
    font-size: 1rem;
    padding: 1rem;
  }
  .experience-item {
    flex-direction: column;
    place-items: center;
  }
  .experience-description {
    order: 1;
  }
  .experience-description h1 {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 350px) {
  .experience-header {
    font-size: 0.75rem;
  }
}
