.home-main {
  /* background-image: url('https://cdn.pixabay.com/photo/2021/04/10/10/32/graphic-6166581_1280.jpg'); */
  /* background-image: url('https://d346xxcyottdqx.cloudfront.net/wp-content/uploads/2016/02/Big-data-storage-e1455012396729.jpg'); */
  /* background-image: url(https://www.newenglandinnsandresorts.com/sites/default/files/styles/2048/public/media-images/Jordan-Pond-Acadia-National-Park.jpg?itok=YaNtml2f); */
  /* background-image: url(https://images.fineartamerica.com/images-medium-large/calm-blue-water-disappearing-into-axiom-photographic.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;

  display: grid;
  place-items: center;
}

.home-title {
  border-radius: 2rem;
  padding: 4% 2%;
  cursor: default;

  background: rgba(0, 16, 78, 0.75);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);

  display: grid;
  place-content: center;
  min-width: 65%;
  max-width: 95%;
  margin: 2rem 0;
}

.home-title > h1 {
  font-weight: 400;
}

.home-title > .home-title__intro {
  color: rgb(106, 198, 252);
  font-size: 2rem;
}

.home-title > .home-title__name {
  font-size: 5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  position: relative;
  color: transparent; /* Hide the original text color */

  /* Gradient for border */
  border-bottom: 4px solid;
  background-image: linear-gradient(to right, #fd23de, #23fdde);
  background-repeat: no-repeat;
  background-size: 100% 4px;
  background-position: bottom;
}

.home-title > .home-title__name::before {
  content: attr(data-text); /* Use an attribute to duplicate the text */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #fd23de, #23fdde);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; /* Hide the text color, show only the gradient */
  pointer-events: none;
}

.home-title > .home-title__title {
  font-size: 2rem;
  color: rgb(106, 198, 252);
}

.home__social--container {
  display: flex;
  flex-direction: row;
  place-items: center;
  place-content: center;
  width: 100%;
  column-gap: 1rem;
  margin: 2rem 0;
}

.home__social--icon {
  border-radius: 50%;
  padding: 1rem;
  height: 5rem;
  width: 5rem;
  display: grid;
  place-items: center;
  background: rgb(106, 198, 252);
}

.home__social--icon:hover {
  background: black;
}

.home__social--container-mobile {
  display: none;

  margin-top: 2rem;
  row-gap: 1rem;
}

.home__social--icon-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
  transition: none;
}

.home__social--icon-mobile:hover {
  color: white;
  text-decoration: underline;
}

@media only screen and (min-width: 1000px) {
  .home-title > .home-title__intro,
  .home-title > .home-title__title {
    font-size: 3vw;
  }
  .home-title > .home-title__name {
    font-size: 6vw;
  }
}

@media only screen and (max-width: 850px) {
  .home-title {
    border-radius: 10px;
  }

  .home__social--container {
    display: none;
  }

  .home__social--container-mobile {
    display: grid;
    row-gap: 0.5rem;
    /* font-size: 4vw; */
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 350px) {
  .home__social--container-mobile {
    margin-top: 1rem;
  }

  .home-title > .home-title__intro,
  .home-title > .home-title__title {
    font-size: 2rem;
  }
  .home-title > .home-title__name {
    font-size: 4rem;
  }

  .home-title > .home-title__name {
    border: none;
    word-break: break-all;
  }

  .home__social--container {
    display: grid;
    gap: 1rem;
  }

  .home__social--container-mobile {
    display: none;
  }
}
